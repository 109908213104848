import port from "../../package.json"

const baseUrl = port.proxy;

const loginApi = baseUrl + "login";
const logoutApi = baseUrl + "logout";
const retrieveThreadsApi = baseUrl + "retrieveThreads";
const joinConversationApi = baseUrl + "joinConversation";
const retrieveMessagesApi = baseUrl + "retrieveMessages";
const sendMessagesApi = baseUrl + "sendMessage";
const leaveConversationApi = baseUrl + "leaveConversation";
const sessionApi = baseUrl + "session";

// const storeNameApi = baseUrl + "storeName";

export { loginApi, logoutApi, retrieveThreadsApi, joinConversationApi, retrieveMessagesApi, sendMessagesApi, leaveConversationApi,sessionApi };