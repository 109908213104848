import React, { useState, useEffect } from "react"
import "../CSS/AllChat.css"
import "../CSS/Chat.css"
// import { Link, useSearchParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import { retrieveThreadsApi } from './ApiConfiguration';
import { BsHourglassSplit, BsPinAngle } from "react-icons/bs"
import { usePromiseTracker } from "react-promise-tracker";
import { Audio } from 'react-loader-spinner';

function AllChat() {
  // var convid = '';
  const navigate = useNavigate();
  const [post, getPost] = useState([]);
  const [loading, setLoading] = useState(false)

  // const [searchParams, setSearchParams] = useSearchParams();

  const fetchPost = () => {
    fetch(retrieveThreadsApi + "?brandid=" + localStorage.getItem('brandid'), { headers: { Authorization: `bearer ${localStorage.getItem('token')}` } }).then((res) => res.json()).then((res) => {
      getPost(res.threads);
      function timeout() {
        setLoading(false)
      }
      setTimeout(timeout, 2000)
    })
  }

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate("/")
    }
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      fetchPost()
    }, 500);
    return () => clearInterval(interval);
  }, [post])


  const logo = localStorage.getItem('BrandLogo')
  //console.log("Conversation Id from URL:", searchParams.get("conversationId"));
  function callMe() {
    setLoading(true)
  }

  const LoadingIndicator = props => {
    const { promiseInProgress } = usePromiseTracker();

    return promiseInProgress &&
      <div
        style={{
          width: "100%",
          height: "100",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1,
        }}
      >
        <Audio type="ThreeDots" color="#2BAD60" height="100" width="100" />
      </div>
  };


  return (
    <>
      {loading && (<div className="loader-container"></div>)}
      <div className='chatmain2'>
        <div className='logo-search'>
          <div className='company-logo'> <img src={logo} alt='brand_logo' /> </div>
        </div>

        <div className='chat-message-list2'>
          {post.map((item, i) => {
            const displayName = item.displayName || "User Name";
            const userName = displayName.toLowerCase();
            var splitUserName = userName.split(' ')
            var userProfileImage = splitUserName[0].charAt(0).toUpperCase();
            let stringUser = String(item.displayName)

            return <div key={i}>

              <Link
                to={{
                  pathname: '/userchat',
                  search: `?conversationId=${item.conversationId}&state=${item.state}&placeId=${item.placeId}`,
                  hash: `#${encodeURIComponent(item.displayName)}`
                }}
                style={{ textDecoration: "none", textTransform: "capitalize" }}
              >

                <div style={{ display: "flex", justifyContent: "space-between", padding: "10px", color: "black" }} key={i}>
                  <div className="d-flex gap-1" style={{ alignItems: "center" }}>
                    {
                      item.state === "Bot" ? <div className="profilePictureAll"> {userProfileImage} </div> : item.state === "Live Agent" ? <div className="profilePicture2All"> {userProfileImage} </div> : <div className="profilePicture3All"> {userProfileImage}
                      </div>
                    }
                    <div>
                      {item.displayName}
                      {item.state === "Queued" ? <div style={{ fontSize: "13px", color: "red" }}>  Requested   <BsHourglassSplit /> ( {item.lastUpdated} )</div> : null}
                    </div>
                  </div>
                  <div> <FcGoogle style={{ alignItems: "center" }} /></div>
                </div>
              </Link>

              <div className="time_lastMessage2">
                <div className="lastMessage2"> {item.lastMessageText} </div>
                <div className="time2"> {item.lastUpdated} </div>
              </div>

              {
                !item.storeName ? <div style={{ fontSize: "14px", marginLeft: "15px" }} > <BsPinAngle /> &nbsp;No store available  </div> : <div style={{ fontSize: "14px", marginLeft: "15px" }} > <BsPinAngle /> {item.storeName}</div>
              }
              <hr />
            </div>

          })}
        </div>

      </div>
    </>
  )
}
export default AllChat