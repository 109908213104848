import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Dashboard from './Components/Dashboard';
import Login from './Components/Login';
import UserChat from './Components/UserChat';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/userchat" element={<UserChat />} />
              {/* <Route path="*" element={<> Page not found</>} />       */}
              <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
