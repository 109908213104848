import React from 'react'
import "../CSS/Rightmsgs.css"
import {MdMessage} from "react-icons/md"
function RightMsgs() {
  const brandLogo = localStorage.getItem('brandName')
  console.log("-------++",brandLogo);
  return (
    <>
      <div className='backgroundImage'>
        <div className='innerContent'>
          <div className="avatar-title bg-soft-primary rounded-circle">
          <h1 className='welcome-Data'><MdMessage/></h1>
          </div>
          <h4 className='welcome-head'>Welcome to {brandLogo} Chat App</h4>
          <p className='welcome-para text-muted mb-4'>Customer relationship management (CRM) is a technology for managing all your company's relationshipsand interactions with customers and potential customers .</p>
        </div>
      </div>

    </>
  )
}
export default RightMsgs

