import React, { useState, useEffect } from "react"
import "../CSS/Chat.css"
// import { Link, useSearchParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import { BsHourglassSplit, BsPinAngle } from 'react-icons/bs'
import { retrieveThreadsApi } from './ApiConfiguration';
import { usePromiseTracker } from "react-promise-tracker";
import { Audio } from 'react-loader-spinner';
import axios from "axios";

function Chat() {

  const navigate = useNavigate();

  // var convid = '';
  const [post, getPost] = useState([]);
  const [loading, setLoading] = useState(false)

  // const [searchParams, setSearchParams] = useSearchParams();

  const fetchPost = () => {

    fetch(retrieveThreadsApi + "?brandid=" + localStorage.getItem('brandid'), { headers: { Authorization: `bearer ${localStorage.getItem('token')}` } }).then((res) => res.json()).then((res) => {
      if (!localStorage.getItem('token')) {
        navigate('/')
      } else {
        getPost(res.threads);
        function timeout() {
          setLoading(false)
        }
        setTimeout(timeout, 2000)
      }
    })
  }



  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate("/")
    }
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      fetchPost()
    }, 500);
    return () => clearInterval(interval);
  }, [post])

  const logo = localStorage.getItem('BrandLogo')
  // const secondaryColor = localStorage.getItem('secondaryColor')
  // console.log("Conversation Id from URL:", searchParams.get("conversationId"));
  function callMe() {
    setLoading(true)
  }

  const LoadingIndicator = props => {
    const { promiseInProgress } = usePromiseTracker();

    return promiseInProgress &&
      <div
        style={{
          width: "100%",
          height: "100",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Audio type="ThreeDots" color="#2BAD60" height="100" width="100" />
      </div>
  };


  return (
    <>
      {loading && (<div className="loader-container"></div>)}
      <div className='chatmain'>
        <div className='logo-search'>
          <div className='company-logo'> <img src={logo} alt='brand_logo' /> </div>
        </div>

        <div className='chat-message-list'>
          {
            post.map((item, i) => {
              const displayName = item.displayName || "User Name";
              const userName = displayName.toLowerCase();
              var splitUserName = userName.split(' ')
              var userProfileImage = splitUserName[0].charAt(0).toUpperCase()
              let stringUser = String(item.displayName)
              // console.log(userProfileImage);
              // console.log(item);
              // console.log('uname: '+item.userName);
              // console.log("Store Name:", item.storeName);
              // convid = item.conversationId;l

              return <div key={i}>

                {/* <Link onClick={callMe} to={{ pathname: '/userchat', search: `?conversationId=${item.conversationId}&state=${item.state}&placeId=${item.placeId}&userName=${stringUser}` }}
                  style={{ textDecoration: "none", textTransform: "capitalize" }}> */}
                <Link
                  to={{
                    pathname: '/userchat',
                    search: `?conversationId=${item.conversationId}&state=${item.state}&placeId=${item.placeId}`,
                    hash: `#${encodeURIComponent(item.displayName)}`
                  }}
                  style={{ textDecoration: "none", textTransform: "capitalize" }}
                >


                  <div style={{ display: "flex", justifyContent: "space-between", padding: "10px", color: "black" }} key={i}>
                    <div className="d-flex gap-1" style={{ alignItems: "center" }}>
                      {
                        item.state === "Bot" ? <div className="profilePicture"> {userProfileImage} </div> : item.state === "Live Agent" ? <div className="profilePicture2"> {userProfileImage} </div> : <div className="profilePicture3"> {userProfileImage} </div>
                      }
                      <div>
                        {item.displayName}
                        {item.state === "Queued" ? <div style={{ fontSize: "13px", color: "red" }}>  Requested   <BsHourglassSplit /> ( {item.lastUpdated} )</div> : null}
                      </div>
                    </div>
                    <div> <FcGoogle style={{ alignItems: "center" }} /> </div>
                  </div>
                </Link>

                <div className="time_lastMessage">
                  <div className="lastMessage"> {item.lastMessageText} </div>
                  <div className="time"> {item.lastUpdated} </div>
                </div>
                {
                  !item.storeName ? <div style={{ fontSize: "14px", marginLeft: "15px" }} > <BsPinAngle /> &nbsp;No store available  </div> : <div style={{ fontSize: "14px", marginLeft: "15px" }} > <BsPinAngle /> {item.storeName}</div>
                }
                <hr />
              </div>
            })}
        </div>
      </div>
    </>
  )
}

export default Chat