import React from 'react'
import SideBar from './SideBar'
import Messages from './Messages'
import AllChat from './AllChat'

function UserChat() {
    return (
        <div className='all-in-one'>
            <SideBar />
            <AllChat/>
            <Messages />
        </div>
    )
}
export default UserChat
