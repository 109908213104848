import React, { useEffect } from 'react'
import SideBar from './SideBar'
import Chat from './Chat'
import RightMsgs from './RightMsgs'
import SideBarBottom from './SideBarBottom'
import "../CSS/DashBoard.css"
import { useNavigate } from 'react-router-dom'
function Dashboard() {
  const navigate = useNavigate();

  useEffect(()=>{
    if(!localStorage.getItem('token')){
      navigate("/")
    }
  },[])

  return (
    <>  
    <div  className='all-in-one'>
      <SideBar/>
      <Chat/>
      <RightMsgs/>
    <SideBarBottom/>
    </div>
    </>
  )
}

export default Dashboard
