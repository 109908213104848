import React, { useState, useEffect } from "react"
import "../CSS/Messages.css"
import { BiArrowBack } from "react-icons/bi"
import { useSearchParams } from "react-router-dom";
import { MdSend } from "react-icons/md";
import { useNavigate } from "react-router-dom"
import { retrieveMessagesApi, sendMessagesApi, leaveConversationApi, joinConversationApi } from "./ApiConfiguration"
import ScrollableFeed from 'react-scrollable-feed'
import { Link } from 'react-router-dom';
import { trackPromise } from 'react-promise-tracker';
function Messages() {
    // const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const [post, setPost] = useState([]);
    const [message, setMessage] = useState([]);

    let conversationId = searchParams.get("conversationId");
    const brand_name = localStorage.getItem("brandName")
    let placeId = searchParams.get("placeId");
    let userName = searchParams.get("userName");
    const state = searchParams.get("state")
    const bottomRef = React.useRef(null);
    const [loading, setLoading] = useState(false)
    // console.log("StateStateStateState", state);

    // Handle Input
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setMessage({ ...message, [name]: value });
    };

    const gotoHome = useNavigate();
    const appendData = (path) => {
        gotoHome(path);
    }

    // Handle Submit
    const sendAgentMessage = (event) => {
        event.preventDefault();
        var msg = document.getElementById('messageSend').value
        var finalmsg = msg.replace(/ /g, '')

        if (finalmsg != '') {
            fetch(sendMessagesApi, {
                method: "POST",
                body: JSON.stringify({
                    "conversationId": conversationId,
                    "message": message.message,
                    "brand_name": brand_name,
                    "userName": userName
                }),

                headers: {
                    "Content-type": "application/json",
                    Authorization: `bearer ${localStorage.getItem('token')}`

                },
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log(data);
                    event.target.reset()
                })
                .catch((err) => {
                    console.log(err.message);
                });

            setMessage('');
        }
        var inputValue = document.getElementById('messageSend');
        inputValue.value = '';
    };
    let dataCheck;
    const fetchPost = () => {
        dataCheck = 0
        trackPromise(
            fetch(retrieveMessagesApi + "?conversationId=" + conversationId + "&placeId=" + placeId + "&userName=" + userName, { headers: { Authorization: `bearer ${localStorage.getItem('token')}` } })
                .then((res) => res.json())
                .then((res) => {

                    setPost(res.messages);
                    dataCheck = 1
                }));

    };
    // Join Conversation
    const joinConversation = () => {
        fetch(joinConversationApi, {
            method: 'POST',
            body: JSON.stringify({
                conversationId, brand_name
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                Authorization: `bearer ${localStorage.getItem('token')}`
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    // Leave Conversation
    const leaveConversation = () => {
        appendData(`/userchat?conversationId=${conversationId}`);

        fetch(leaveConversationApi, {
            method: 'POST',
            body: JSON.stringify({
                conversationId, brand_name
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                Authorization: `bearer ${localStorage.getItem('token')}`

            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
            })
            .catch((err) => {
                console.log(err.message);
            });

    }

    // useEffect(()=>{
    //     if(!localStorage.getItem('token')){
    //       navigate("/")
    //     }
    //   },[])



    useEffect(() => {
        const interval = setInterval(() => {
            fetchPost()
        }, 500);
        return () => clearInterval(interval);
    }, [post]);

    useEffect(() => {
        bottomRef.current?.scrollIntoView();

    }, [message]);

    return (
        <>



            <div className='backgroundImageM'>
                <header>
                    <div className="main1">
                        <div className="d-flex gap-3">
                            <Link to='/dashboard' className='text-secondary'> <div className="main2"><BiArrowBack className="backarrow" /></div> </Link>
                            <div className="profilePictureMessage">
                                {
                                    post.map((item, i) => {
                                        const displayName = item.displayName || "User Name";
                                        const userName = displayName.toLowerCase();
                                        var splitUserName = userName.split(' ')
                                        var userProfileImage = splitUserName[0].charAt(0).toUpperCase()
                                        return <div key={i}>  {userProfileImage[i]} </div>
                                    })
                                }
                            </div>

                            <div className="main2">
                                <h6 className="user-name-header"> {post.length && post[0].displayName}</h6>
                                <span className="activeshow" > Active</span>
                            </div>
                        </div>

                        <div className="d-flex gap-3">
                            {
                                state === "Live Agent" ? <Link to={{ pathname: '/userchat', search: `?conversationId=${conversationId}&state=Bot&placeId=${placeId}&userName=${userName}` }}>   <button type="button" onClick={leaveConversation} className="btn  btn-outline-danger">Leave Chat</button> </Link> : <Link to={{ pathname: '/userchat', search: `?conversationId=${conversationId}&state=Live Agent&placeId=${placeId}&userName=${userName}` }}><button type="button" onClick={joinConversation} className="btn btn-outline-primary">Join Chat</button>
                                </Link>
                            }
                        </div>
                    </div>
                </header>

                <div id="messageBottom">
                    <ScrollableFeed>
                        {
                            post.map((item, i) => {
                                const displayName = item.displayName || "User Name";
                                const userName = displayName.toLowerCase();
                                var splitUserName = userName.split(' ')
                                var userProfileImage = splitUserName[0].charAt(0).toUpperCase();
                                // Inside the map function where you render messages
                                let isLink = /(https?:\/\/[^\s]+)/.test(item.messageText);
                                if (typeof item.messageText !== "string") {
                                    return null; 
                                }

                                let msg = item.messageText;
                                let urlMatches = msg.match(/(https?:\/\/[^\s]+)/);

                                if (urlMatches) {
                                    // If there's a link, extract the URL and the text before it
                                    let url = urlMatches[0];
                                    let beforeUrl = msg.substring(0, urlMatches.index);
                                    let afterUrl = msg.substring(urlMatches.index + url.length);

                                    return (
                                        <div className="mx-3" key={i}>
                                            {
                                                item.userType === "User" ?
                                                    <div>
                                                        <div className="d-flex">
                                                            <div className="profilePictureMessage"> {userProfileImage} </div>
                                                            <div className="message-main text-start message-main m-2 ">
                                                                <span> {beforeUrl} <a className="text-primary" target="_blank" rel="noopener noreferrer" href={url}>{url}</a> {afterUrl} </span>
                                                            </div>
                                                        </div>
                                                        <span className="myClasstime2">{item.createdDate}</span>
                                                    </div>
                                                    :
                                                    <div className="myClass">
                                                        <div className="message-body m-2">
                                                            <span> {beforeUrl} <a className="text-primary" target="_blank" rel="noopener noreferrer" href={url}>{url}</a> {afterUrl} </span>
                                                        </div>
                                                        <span className="myClasstime">{item.createdDate}</span>
                                                    </div>
                                            }
                                        </div>
                                    );
                                } else {
                                    // If no link found, render the message as is
                                    return (
                                        <div className="mx-3" key={i}>
                                            {
                                                item.userType === "User" ?
                                                    <div>
                                                        <div className="d-flex">
                                                            <div className="profilePictureMessage"> {userProfileImage} </div>
                                                            <p className="message-main m-2 "> {msg}</p>
                                                        </div>
                                                        <span className="myClasstime2">{item.createdDate}</span>
                                                    </div>
                                                    :
                                                    <div className="myClass">
                                                        <p className="message-body"> {msg}</p>
                                                        <span className="myClasstime">{item.createdDate}</span>
                                                    </div>
                                            }
                                        </div>
                                    );
                                }

                            })}

                        {
                            state === "Queued" ? <div className="text-danger text-center opacity-75"> User is requested to connect with live agent</div> : null
                        }

                    </ScrollableFeed>
                    <div ref={bottomRef} />
                </div>

                <footer>
                    <form onSubmit={sendAgentMessage} className="footer-form">
                        <div className="d-flex gap-2 justify-content-center">
                            <div className="chat-box">
                                {state === "Live Agent" ? <input type="text" id="messageSend" name="message" value={message.name} onChange={handleInputChange} placeholder="type here...." className="form-control" autoComplete="off" /> : <input type="text" id="messageSend" name="message" value={message.name} onChange={handleInputChange} placeholder="type here...." className="form-control" autoComplete="off" style={{ width: "48vw", display: "none" }} />
                                }
                            </div>
                            {
                                state === "Live Agent" ? <button type="submit" className="sendMessage-btn" ><MdSend className="md-inner" /></button> : <button type="submit" style={{ display: "none" }}  ><MdSend /></button>
                            }
                        </div>
                    </form>
                </footer>
            </div>
        </>
    )
}

export default Messages
